const DateFormat = "MM/DD/yyyy";
const DateTimeFormat = "MM/DD/yyyy HH:mm:ss";
const SystemDateFormat = "yyyy-MM-DD";
const DateFormatCalenderInHistoricReports = "MM-DD-YYYY";
// const DateFormat = "DD-MM-yyyy";
// const DateTimeFormat = "DD-MM-yyyy HH:mm:ss";

// Prod-properties (InCcaas)
const LOGIN_URL = 'https://api.preprod-cx.ozonetel.com/auth/login';
const BASE_URL = 'https://api.preprod-cx.ozonetel.com/dashboardApi';
const BASE_API_URL = 'https://api.preprod-cx.ozonetel.com/reportApi/endpoint';
const LIVE_BASE_URL = 'https://api.preprod-cx.ozonetel.com/liveReportApi';
const ADMIN_BASE_URL = 'https://api.preprod-cx.ozonetel.com/ca-admin-Api/CloudAgentAPI';
const AGENT_LOGIN_URL = 'https://agent.preprod-cx.ozonetel.com/login'
const CRM_REDIRECT_URL = "https://crm.auth.ozonetel.com/auth/zoho/integration";
const IVR_FLOW_URL = 'https://ivr.preprod-cx.ozonetel.com/ivrdd';
const LOGIN_PAGE_BACKGROUND_IMG_URL = "https://s3.ap-south-1.amazonaws.com/ca.cdn/";
const UPDATE_PASSWORD_URL = 'https://api.preprod-cx.ozonetel.com/updatepassword';
const FORGOT_PASSWORD_URL = 'https://api.preprod-cx.ozonetel.com/resetpassword';
const IMAGE_UPLOAD_URL = 'https://api.pre-prod.ozonetel.com';
const APP_URL = 'http://kookoo_apps/DDParser/v1/index.php?tag_id=start&flow_id=';
const APXOR_KEY = '7cc77eb4-548d-41d8-afc9-364d564627ee';
const LOCATION = 'IN';
const SC_RECORDING_URL = 'https://sc-recording.pre-prod.ozonetel.com';
 
//export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, FORGOT_PASSWORD_URL, APP_URL, UPDATE_PASSWORD_URL }

//export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DATE_FORMAT, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, TIME_FORMAT, ISO_DATE_FORMAT_FILTER }

//export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL }

//export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, IMAGE_UPLOAD_URL }

export { LOGIN_PAGE_BACKGROUND_IMG_URL, LOGIN_URL, DateFormatCalenderInHistoricReports, BASE_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, CRM_REDIRECT_URL, AGENT_LOGIN_URL, IVR_FLOW_URL, DateTimeFormat, DateFormat, SystemDateFormat, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL, APP_URL, IMAGE_UPLOAD_URL,APXOR_KEY,LOCATION, SC_RECORDING_URL }
